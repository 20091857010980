import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client';
import { contentfulClient } from '../apollo';

// we need to specify the Client and pass it as an option because we can't have nested ApolloProviders in a single app,
// so useQuery can't get the client from the context. Once Better Neighborhoods app is separate from Neighbors Web
// we're going to be able to remove this hook
export const useContentfulQuery = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: Omit<QueryHookOptions<TData, TVariables>, 'errorPolicy'> = {},
) =>
  useQuery<TData, TVariables>(query, {
    ...options,
    client: contentfulClient,
  });
