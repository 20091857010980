import { ErrorResponse } from '@apollo/client/link/error';
import { NeighborsErrorCodes } from './codes';

interface GenericErrorWithGqlErrors
  extends Pick<ErrorResponse, 'graphQLErrors'> {}

export const hasGqlErrorCode = (
  { graphQLErrors = [] }: GenericErrorWithGqlErrors,
  code: NeighborsErrorCodes,
) => graphQLErrors.some(({ extensions }) => extensions?.code === code);

export const hasGqlErrorCodes = (
  { graphQLErrors = [] }: GenericErrorWithGqlErrors,
  codes: NeighborsErrorCodes[],
) => graphQLErrors.some(({ extensions }) => codes.includes(extensions?.code));

export const getISOTimestamp = (): String => new Date().toISOString();
