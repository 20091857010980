import { TFunction } from 'i18next';
import { array, number, object, string, boolean } from 'yup';

export const MAX_CHARACTERS_TITLE = 100;

export const MAX_CHARACTERS_DESCRIPTION = 2500;

export const getSchema = (t: TFunction, isNpssUser?: boolean) =>
  object().shape({
    location: object({
      label: string(),
      value: string(),
    })
      .nullable()
      .required(t('schema.location-required')!),
    title: string()
      .max(
        MAX_CHARACTERS_TITLE,
        t('schema.title-max-chars', { maxChars: MAX_CHARACTERS_TITLE })!,
      )
      .required(t('schema.title-required')!),
    description: string()
      .max(
        MAX_CHARACTERS_DESCRIPTION,
        t('schema.description-max-chars', {
          maxChars: MAX_CHARACTERS_DESCRIPTION,
        })!,
      )
      .when('title', (_, schema) =>
        isNpssUser
          ? schema.required(t('schema.description-required')!)
          : schema.optional(),
      ),
    disableComments: boolean().nullable(),
    disablePushNotifications: boolean().nullable(),
  });

export const getSchemaForNpss = (t: TFunction) =>
  object().shape({
    location: object({
      label: string(),
      value: string(),
    })
      .nullable()
      .when('locationType', ([locationType], schema) => {
        if (locationType?.value === 'specificLocation') {
          return schema.required(t('schema.address-required')!);
        }
        return schema;
      }),
    locationType: object({
      label: string(),
      value: string(),
    }).required(t('schema.location-type-required')!),
    areas: array(
      object({
        id: number(),
        type: string(),
        disabled: boolean(),
        value: object().shape({
          id: string(),
          label: string(),
        }),
      }),
    )
      .nullable()
      .when('locationType', ([locationType], schema) => {
        if (locationType?.value === 'regionalPost') {
          return schema.min(1, t('schema.area-required')!).required();
        }
        return schema;
      }),
  });
