import { flatten } from 'flat';
import { FontSizeProps, FontWeightProps, LineHeightProps } from 'styled-system';
import { fontSizes, fontWeights } from './fonts';

const textStylesDefinitions = {
  heading: {
    large: {
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: fontSizes[6],
        lineHeight: '36px',
      },
    },
    normal: {
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: fontSizes[5],
        lineHeight: '32px',
      },
    },
    small: {
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: fontSizes[4],
        lineHeight: '28px',
      },
      regular: {
        fontWeight: fontWeights.regular,
        fontSize: fontSizes[4],
        lineHeight: '28px',
      },
      light: {
        fontWeight: fontWeights.light,
        fontSize: fontSizes[4],
        lineHeight: '28px',
      },
    },
  },
  body: {
    large: {
      bold: {
        fontWeight: fontWeights.bold,
        fontSize: fontSizes[3],
        lineHeight: '24px',
      },
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: fontSizes[2],
        lineHeight: '24px',
      },
      regular: {
        fontWeights: fontWeights.regular,
        fontSize: fontSizes[2],
        lineHeight: '24px',
      },
      light: {
        fontWeight: fontWeights.light,
        fontSize: fontSizes[2],
        lineHeight: '24px',
      },
    },
    normal: {
      bold: {
        fontWeight: fontWeights.bold,
        fontSize: fontSizes[1],
        lineHeight: '24px',
      },
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: fontSizes[1],
        lineHeight: '24px',
      },
      regular: {
        fontWeight: fontWeights.regular,
        fontSize: fontSizes[1],
        lineHeight: '24px',
      },
      light: {
        fontWeight: fontWeights.light,
        fontSize: fontSizes[1],
        lineHeight: '24px',
      },
    },
    small: {
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: fontSizes[0],
        lineHeight: '18px',
      },
      regular: {
        fontWeight: fontWeights.regular,
        fontSize: fontSizes[0],
        lineHeight: '18px',
      },
      light: {
        fontWeight: fontWeights.light,
        fontSize: fontSizes[0],
        lineHeight: '18px',
      },
    },
  },
  textlink: {
    large: {
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: fontSizes[2],
        lineHeight: '24px',
      },
    },
    normal: {
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: fontSizes[1],
        lineHeight: '24px',
      },
    },
    small: {
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: fontSizes[0],
        lineHeight: '18px',
      },
    },
    xsmall: {
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: '11px',
        lineHeight: '18px',
      },
    },
  },
  footnote: {
    xsmall: {
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: '11px',
        lineHeight: '16.5px',
      },
      regular: {
        fontWeight: fontWeights.regular,
        fontSize: '11px',
        lineHeight: '16.5px',
      },
      light: {
        fontWeight: fontWeights.light,
        fontSize: '11px',
        lineHeight: '16.5px',
      },
    },
  },
} as const;

export type TextStyle =
  | 'body-large-bold'
  | 'body-large-medium'
  | 'body-large-regular'
  | 'body-large-light'
  // body-normal
  | 'body-normal-bold'
  | 'body-normal-medium'
  | 'body-normal-regular'
  | 'body-normal-light'
  // body-small
  | 'body-small-medium'
  | 'body-small-regular'
  | 'body-small-light'
  // heading-large
  | 'heading-large-medium'
  // heading-normal
  | 'heading-normal-medium'
  // heading-small
  | 'heading-small-medium'
  | 'heading-small-regular'
  | 'heading-small-light'
  // footnote-xsmall
  | 'footnote-xsmall-medium'
  | 'footnote-xsmall-regular'
  | 'footnote-xsmall-light'
  // textlink
  | 'textlink-large-medium'
  | 'textlink-normal-medium'
  | 'textlink-small-medium'
  | 'textlink-xsmall-medium';

export type TextStyleTypographyProps = FontWeightProps &
  FontSizeProps &
  LineHeightProps;

export const textStyles = flatten(textStylesDefinitions, {
  delimiter: '-',
  maxDepth: 3,
}) as Record<TextStyle, TextStyleTypographyProps>;
