import ReactPlayer, { FileConfig } from 'react-player/file';
import { RemoveIndex } from 'src/shared/types/utils';
import { PauseButton, PlayButton } from './VideoPlayerButton';
import {
  useVideoPlayer,
  UseVideoPlayerProps,
  UseVideoPlayerReturn,
} from './useVideoPlayer';
import { Box, BoxProps } from '../Box';
import type { ReactNode, VideoHTMLAttributes } from 'react';
import type { BaseReactPlayerProps } from 'react-player/base';

export type VideoAttributes = VideoHTMLAttributes<HTMLVideoElement>;

interface FilePlayerProps extends RemoveIndex<BaseReactPlayerProps> {
  config?: Omit<FileConfig, 'attributes'> & { attributes?: VideoAttributes };
}

type OwnFilePlayerProps = Omit<
  FilePlayerProps,
  'controls' | 'height' | 'onPause' | 'onPlay' | 'playing' | 'width'
>;

export interface VideoPlayerProps
  extends UseVideoPlayerProps,
    OwnFilePlayerProps,
    Pick<BoxProps, 'className' | 'borderRadius'> {
  pButton?: (props: PlayButtonIconProps) => ReactNode;
}

export const VideoPlayer = ({
  borderRadius,
  className,
  pButton = VideoPlayerButton,
  reproducible = true,
  ...props
}: VideoPlayerProps) => {
  const {
    containerRef,
    isPlaying,
    isVideoHover,
    setPause,
    setPlay,
    setVideoHoverFalse,
    setVideoHoverTrue,
    showControls,
  } = useVideoPlayer({ reproducible });

  const playButton = pButton({
    isPlaying,
    setPause,
    setPlay,
  });

  return (
    <Box
      borderRadius={borderRadius}
      className={className}
      cursor="pointer"
      height="100%"
      onMouseEnter={setVideoHoverTrue}
      onMouseLeave={setVideoHoverFalse}
      position="relative"
      ref={containerRef}
      width={1}
      zIndex="videoPlayer"
    >
      {(!isPlaying || isVideoHover) && playButton}
      <ReactPlayer
        {...props}
        controls={showControls}
        height="100%"
        onPause={setPause}
        onPlay={setPlay}
        playing={isPlaying}
        width="100%"
      />
    </Box>
  );
};

type PlayButtonIconProps = Pick<
  UseVideoPlayerReturn,
  'isPlaying' | 'setPlay' | 'setPause'
>;

const VideoPlayerButton = ({
  isPlaying,
  setPause,
  setPlay,
}: PlayButtonIconProps) =>
  isPlaying ? (
    <PauseButton onClick={setPause} />
  ) : (
    <PlayButton onClick={setPlay} />
  );
