import { NamedExoticComponent, ReactElement, ReactNode } from 'react';
import { IntrinsicElementsKeys } from 'src/shared/types/utils';
import { isLocalBuild } from 'src/utils';
import styled from 'styled-components';
import {
  css,
  CSSProps,
  defaults,
  PseudoProps,
  pseudos,
  shouldForwardProp,
  styledSystem,
  StyledSystemProps,
  sx,
  SxProps,
} from './Props';

export interface BoxOwnProps
  extends CSSProps,
    SxProps,
    PseudoProps,
    StyledSystemProps {}

export type BoxProps<T extends IntrinsicElementsKeys = 'div'> = Omit<
  Omit<JSX.IntrinsicElements[T], 'ref'>,
  keyof BoxOwnProps
> &
  BoxOwnProps & {
    children?: ReactNode;
    as?: T;
  };

export type BoxBasedComponentProps<
  // the intrinsic element the component ends up rendering
  T extends IntrinsicElementsKeys = 'div',
  // the additional props the component accepts
  P extends {} = {},
  // additional props the component doesn't accept
  K extends keyof BoxProps<T> | '' = '',
> = Omit<BoxProps<T>, K | keyof P> & P;

export interface ForwardRefBoxComponent<
  T extends IntrinsicElementsKeys = IntrinsicElementsKeys,
> extends Pick<NamedExoticComponent, 'displayName'> {
  <K extends T>(
    props: BoxProps<K> & Pick<JSX.IntrinsicElements[T], 'ref'>,
  ): ReactElement | null;
}

export const Box = styled.div.withConfig({ shouldForwardProp })(
  defaults,
  css,
  styledSystem,
  pseudos,
  sx,
) as ForwardRefBoxComponent;

if (isLocalBuild) {
  Box.displayName = 'Box';
}
