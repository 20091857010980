import {
  ApolloError,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client';
import { DocumentNode } from 'graphql';
import { F } from 'ramda';
import { useErrorHandler } from 'react-error-boundary';
import { NeighborsErrorCodes } from 'src/apollo/codes';

export const useQueryWithBoundary = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: Omit<QueryHookOptions<TData, TVariables>, 'errorPolicy'> & {
    headers?: Record<string, string>;
  },
  shouldIgnoreError: (error: ApolloError) => boolean = F,
) => {
  const { headers, ...restOptions } = options;

  const { error, ...result } = useQuery<TData, TVariables>(query, {
    ...restOptions,
    context: {
      headers: headers ?? {},
    },
    errorPolicy: 'all',
  });

  const ignoreError = Boolean(error) && shouldIgnoreError(error!);

  const givenError = ignoreError ? undefined : error;

  const setError = useErrorHandler(givenError);

  return { ...result, error, setError };
};

const createGqlErrorIncludes =
  (searchElement: string, errorCode?: string) => (error: ApolloError) => {
    if (errorCode !== undefined) {
      return error.graphQLErrors.some(
        (err) => err.extensions?.code === errorCode,
      );
    }
    return error.graphQLErrors.some(({ path }) =>
      path?.includes(searchElement),
    );
  };

export const shouldIgnoreCommentCountError =
  createGqlErrorIncludes('comment_count');

export const shouldIgnoreCommentConnectionError =
  createGqlErrorIncludes('commentConnection');

export const shouldIgnoreUnauthorized = createGqlErrorIncludes(
  '4-N',
  'UNAUTHORIZED',
);

export const shouldIgnorePetNotFound = createGqlErrorIncludes(
  'petProfileById',
  NeighborsErrorCodes.NotFound,
);
