import {
  DocumentNode,
  Unmasked,
  useApolloClient,
  useQuery,
} from '@apollo/client';
import { useCallback } from 'react';
import {
  GET_ACTIVE_EVENT_ID,
  GET_OPENED_EVENT_ID,
  GET_READ_EVENTS,
} from './local';

const useApolloLocalStateUpdate = <T>(query: DocumentNode) => {
  const client = useApolloClient();

  return useCallback(
    (nextState: T) => {
      client.writeQuery({
        query,
        data: nextState as Unmasked<T>,
      });
    },
    [client, query],
  );
};

type UseApolloLocalStateReturn<T> = [T, (value: T) => void];

const useApolloLocalState = <T extends Record<string, any>>(
  query: DocumentNode,
) => {
  const { data: state } = useQuery<T>(query);
  const setState = useApolloLocalStateUpdate(query);

  return [state, setState] as UseApolloLocalStateReturn<T>;
};

type ActiveEventId = { activeEventId: string | null };

export const useSetActiveEventId = () => {
  const originalSetState =
    useApolloLocalStateUpdate<ActiveEventId>(GET_ACTIVE_EVENT_ID);

  const setState = useCallback(
    (nextState: ActiveEventId['activeEventId']) => {
      originalSetState({
        activeEventId: nextState,
      });
    },
    [originalSetState],
  );

  const remove = useCallback(() => {
    setState(null);
  }, [setState]);

  return [setState, remove] as const;
};

export const useActiveEventId = () => {
  const [{ activeEventId }] =
    useApolloLocalState<ActiveEventId>(GET_ACTIVE_EVENT_ID);

  const [setState, remove] = useSetActiveEventId();

  return [activeEventId, setState, remove] as const;
};

type OpenedEventId = { openedEventId: string | null };

export const useSetOpenedEventId = () => {
  const originalSetState =
    useApolloLocalStateUpdate<OpenedEventId>(GET_OPENED_EVENT_ID);

  const setState = useCallback(
    (nextState: OpenedEventId['openedEventId']) => {
      originalSetState({
        openedEventId: nextState,
      });
    },
    [originalSetState],
  );

  const remove = useCallback(() => {
    setState(null);
  }, [setState]);

  return [setState, remove] as const;
};

export const useOpenedEventId = () => {
  const [{ openedEventId }, originalSetState] =
    useApolloLocalState<OpenedEventId>(GET_OPENED_EVENT_ID);

  const setState = useCallback(
    (nextState: OpenedEventId['openedEventId']) => {
      originalSetState({
        openedEventId: nextState,
      });
    },
    [originalSetState],
  );

  const remove = useCallback(() => {
    setState(null);
  }, [setState]);

  return [openedEventId, setState, remove] as const;
};

type ReadEvents = {
  readEvents: Record<string, boolean>;
};

export const useReadEvents = () => {
  const [{ readEvents }, originalSetState] =
    useApolloLocalState<ReadEvents>(GET_READ_EVENTS);

  const setState = useCallback(
    (nextState: ReadEvents['readEvents']) => {
      originalSetState({
        readEvents: nextState,
      });
    },
    [originalSetState],
  );

  const remove = useCallback(() => {
    setState({});
  }, [setState]);

  return [readEvents, setState, remove] as const;
};
